import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import "./index.scss";
import { Navigation, Footer, Home, About, Work, WorkDetail, NoPage } from "./components";
import { createBrowserHistory } from "history";


ReactGA.initialize("G-BJRJ1B6EGP");
const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.send(location.pathname);
});

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/work" element={<Work />} />
        <Route path="/work/:workId/:title" element={<WorkDetail />} />
        <Route path="/work-details" element={<WorkDetail />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
      <Navigation />
      <Footer />
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
