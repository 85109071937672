import React from "react";
import styles from "./../style/about.module.scss";
import { Helmet } from "react-helmet";

function About() {
  return (
    <>
    <Helmet>
        <title>ASK GRAPHIX | About Us</title>
        <meta
          name="description"
          content="Discover top-notch web development services tailored to your needs. From custom websites to e-commerce solutions, our expert team delivers responsive, SEO-friendly, and visually stunning digital experiences."
        />
      </Helmet>
    <div className={styles.aboutAnees}>
      <div className={`${styles.container} container`}>
        <div className={styles.flex}>
          <div className={styles.aboutTextPan}>
            <div className={styles.textInner}>
              <h1>About Anees</h1>
              <section>
                <p>I am Anees, a Frontend Developer and Coach with 20 years of experience in building cohesive teams and creating innovative products that foster connections. My passion lies in collaboration, designing playful user interfaces, and implementing comprehensive practices to ensure excellence.</p>
                <p>Throughout my extensive career as a UI/UX developer, I have embraced numerous new ideas and practices, continually evolving my skill set to meet the dynamic demands of the industry. I am confident that I can significantly contribute to your business needs and goals by applying sound IT principles.</p>
                <p>With over two decades of professional experience, I have mastered various aspects of web development and user experience, including user metrics analysis, design principles, wireframing, information architecture, accessibility, usability, CSS, HTML, JavaScript, and SEO. I have working knowledge of React.js and Vue.js at a novice level, and I am proficient in WordPress development. My expertise extends to human-computer interaction, usability testing, and prototyping.</p>
                <p>I pride myself on my adaptability and hands-on development experience, coupled with an in-depth understanding of the journey through which I have acquired vast knowledge and skills. I excel in collaborating with designers to analyze and refine wireframes and mockups prior to development, ensuring enhanced usability and a positive consumer experience. I possess a thorough knowledge of user-centered design methodology and can lead overall Web UI/UX development and usability strategies, balancing user needs with business goals.</p>
                <p>My extensive experience and unique skill set distinguish me as a highly qualified professional. I am eager to learn more about your IT department’s plans and to contribute to your organization’s success. I am confident that I can not only help achieve your overall goals but also gain valuable insights that will further enhance my expertise.</p>
                <p>-----</p>
                <p>Thank you for your time and consideration. I look forward to the opportunity to discuss how I can add value to your team.</p>
              </section>
            </div>
          </div>
          <div className={styles.aboutPicPan}>
            <img src="/anees-IMG_4.jpeg" alt="SK Anees pasha" />
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default About;
